@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    background-color: #162A39;
    background-image: url("../img/watercolor-top.png");
    background-repeat: no-repeat;
    background-size: contain;
    color: #FDF0DA;
    font-family: "Patrick Hand SC";
    font-feature-settings: 'liga' 0;
}