@import "https://fonts.googleapis.com/css2?family=Patrick+Hand+SC&display=swap";
*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.visible {
  visibility: visible;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.m-2 {
  margin: .5rem;
}

.m-6 {
  margin: 1.5rem;
}

.m-auto {
  margin: auto;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.mx-1 {
  margin-left: .25rem;
  margin-right: .25rem;
}

.mt-24 {
  margin-top: 6rem;
}

.mt-32 {
  margin-top: 8rem;
}

.mt-36 {
  margin-top: 9rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-48 {
  margin-top: 12rem;
}

.mb-16 {
  margin-bottom: 4rem;
}

.mt-\[-20px\] {
  margin-top: -20px;
}

.mt-16 {
  margin-top: 4rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mt-\[-100px\] {
  margin-top: -100px;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mt-\[-120px\] {
  margin-top: -120px;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-64 {
  margin-bottom: 16rem;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.h-\[420px\] {
  height: 420px;
}

.h-\[500px\] {
  height: 500px;
}

.h-\[300px\] {
  height: 300px;
}

.w-6\/12 {
  width: 50%;
}

.w-\[33\%\] {
  width: 33%;
}

.w-\[25\%\] {
  width: 25%;
}

.w-9\/12 {
  width: 75%;
}

.w-\[36px\] {
  width: 36px;
}

.w-\[400px\] {
  width: 400px;
}

.w-\[100\%\] {
  width: 100%;
}

.w-\[250px\] {
  width: 250px;
}

.w-8\/12 {
  width: 66.6667%;
}

.w-\[50\%\] {
  width: 50%;
}

.grow {
  flex-grow: 1;
}

.rotate-45 {
  --tw-rotate: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.-rotate-45 {
  --tw-rotate: -45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.cursor-pointer {
  cursor: pointer;
}

.justify-center {
  justify-content: center;
}

.rounded-full {
  border-radius: 9999px;
}

.border-4 {
  border-width: 4px;
}

.border-\[\#FBF0DC\] {
  --tw-border-opacity: 1;
  border-color: rgb(251 240 220 / var(--tw-border-opacity) );
}

.p-6 {
  padding: 1.5rem;
}

.p-3 {
  padding: .75rem;
}

.p-4 {
  padding: 1rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.text-center {
  text-align: center;
}

.align-top {
  vertical-align: top;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-6xl {
  font-size: 3.75rem;
  line-height: 1;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.font-bold {
  font-weight: 700;
}

body {
  color: #fdf0da;
  font-feature-settings: "liga" 0;
  background-color: #162a39;
  background-image: url("watercolor-top.77f54cd9.png");
  background-repeat: no-repeat;
  background-size: contain;
  font-family: Patrick Hand SC;
}

.hover\:border-white:hover {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity) );
}

@media (min-width: 1024px) {
  .lg\:w-\[70\%\] {
    width: 70%;
  }

  .lg\:w-\[30\%\] {
    width: 30%;
  }

  .lg\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}

[data-rk] .iekbcc0 {
  box-sizing: border-box;
  text-align: left;
  vertical-align: baseline;
  -webkit-tap-highlight-color: transparent;
  border: 0;
  margin: 0;
  padding: 0;
  font-size: 100%;
  line-height: normal;
}

[data-rk] .iekbcc1 {
  list-style: none;
}

[data-rk] .iekbcc2 {
  quotes: none;
}

[data-rk] .iekbcc2:before, [data-rk] .iekbcc2:after {
  content: "";
}

[data-rk] .iekbcc3 {
  border-collapse: collapse;
  border-spacing: 0;
}

[data-rk] .iekbcc4 {
  appearance: none;
}

[data-rk] .iekbcc5 {
  outline: none;
}

[data-rk] .iekbcc5::placeholder {
  opacity: 1;
}

[data-rk] .iekbcc6 {
  color: inherit;
  background-color: #0000;
}

[data-rk] .iekbcc7:disabled {
  opacity: 1;
}

[data-rk] .iekbcc7::-ms-expand {
  display: none;
}

[data-rk] .iekbcc8::-ms-clear {
  display: none;
}

[data-rk] .iekbcc8::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

[data-rk] .iekbcc9 {
  cursor: pointer;
  text-align: left;
  background: none;
}

[data-rk] .iekbcca {
  color: inherit;
  text-decoration: none;
}

[data-rk] .ju367v0 {
  align-items: flex-start;
}

[data-rk] .ju367v2 {
  align-items: flex-end;
}

[data-rk] .ju367v4 {
  align-items: center;
}

[data-rk] .ju367v6 {
  display: none;
}

[data-rk] .ju367v8 {
  display: block;
}

[data-rk] .ju367va {
  display: flex;
}

[data-rk] .ju367vc {
  display: inline;
}

[data-rk] .ju367ve {
  align-self: flex-start;
}

[data-rk] .ju367vf {
  align-self: flex-end;
}

[data-rk] .ju367vg {
  align-self: center;
}

[data-rk] .ju367vh {
  background-size: cover;
}

[data-rk] .ju367vi {
  border-radius: 1px;
}

[data-rk] .ju367vj {
  border-radius: 6px;
}

[data-rk] .ju367vk {
  border-radius: 10px;
}

[data-rk] .ju367vl {
  border-radius: 13px;
}

[data-rk] .ju367vm {
  border-radius: var(--rk-radii-actionButton);
}

[data-rk] .ju367vn {
  border-radius: var(--rk-radii-connectButton);
}

[data-rk] .ju367vo {
  border-radius: var(--rk-radii-menuButton);
}

[data-rk] .ju367vp {
  border-radius: var(--rk-radii-modal);
}

[data-rk] .ju367vq {
  border-radius: var(--rk-radii-modalMobile);
}

[data-rk] .ju367vr {
  border-radius: 25%;
}

[data-rk] .ju367vs {
  border-radius: 9999px;
}

[data-rk] .ju367vt {
  border-style: solid;
}

[data-rk] .ju367vu {
  border-width: 0;
}

[data-rk] .ju367vv {
  border-width: 1px;
}

[data-rk] .ju367vw {
  border-width: 2px;
}

[data-rk] .ju367vx {
  border-width: 4px;
}

[data-rk] .ju367vy {
  cursor: pointer;
}

[data-rk] .ju367vz {
  flex-direction: row;
}

[data-rk] .ju367v10 {
  flex-direction: column;
}

[data-rk] .ju367v11 {
  font-family: var(--rk-fonts-body);
}

[data-rk] .ju367v12 {
  font-size: 12px;
  line-height: 18px;
}

[data-rk] .ju367v13 {
  font-size: 13px;
  line-height: 18px;
}

[data-rk] .ju367v14 {
  font-size: 14px;
  line-height: 18px;
}

[data-rk] .ju367v15 {
  font-size: 16px;
  line-height: 20px;
}

[data-rk] .ju367v16 {
  font-size: 18px;
  line-height: 24px;
}

[data-rk] .ju367v17 {
  font-size: 20px;
  line-height: 24px;
}

[data-rk] .ju367v18 {
  font-size: 23px;
  line-height: 29px;
}

[data-rk] .ju367v19 {
  font-weight: 400;
}

[data-rk] .ju367v1a {
  font-weight: 500;
}

[data-rk] .ju367v1b {
  font-weight: 600;
}

[data-rk] .ju367v1c {
  font-weight: 700;
}

[data-rk] .ju367v1d {
  font-weight: 800;
}

[data-rk] .ju367v1e {
  gap: 0;
}

[data-rk] .ju367v1f {
  gap: 1px;
}

[data-rk] .ju367v1g {
  gap: 2px;
}

[data-rk] .ju367v1h {
  gap: 3px;
}

[data-rk] .ju367v1i {
  gap: 4px;
}

[data-rk] .ju367v1j {
  gap: 5px;
}

[data-rk] .ju367v1k {
  gap: 6px;
}

[data-rk] .ju367v1l {
  gap: 8px;
}

[data-rk] .ju367v1m {
  gap: 10px;
}

[data-rk] .ju367v1n {
  gap: 12px;
}

[data-rk] .ju367v1o {
  gap: 14px;
}

[data-rk] .ju367v1p {
  gap: 16px;
}

[data-rk] .ju367v1q {
  gap: 18px;
}

[data-rk] .ju367v1r {
  gap: 20px;
}

[data-rk] .ju367v1s {
  gap: 24px;
}

[data-rk] .ju367v1t {
  gap: 28px;
}

[data-rk] .ju367v1u {
  gap: 32px;
}

[data-rk] .ju367v1v {
  gap: 36px;
}

[data-rk] .ju367v1w {
  gap: 44px;
}

[data-rk] .ju367v1x {
  gap: 64px;
}

[data-rk] .ju367v1y {
  gap: -1px;
}

[data-rk] .ju367v1z {
  height: 1px;
}

[data-rk] .ju367v20 {
  height: 2px;
}

[data-rk] .ju367v21 {
  height: 4px;
}

[data-rk] .ju367v22 {
  height: 8px;
}

[data-rk] .ju367v23 {
  height: 12px;
}

[data-rk] .ju367v24 {
  height: 20px;
}

[data-rk] .ju367v25 {
  height: 24px;
}

[data-rk] .ju367v26 {
  height: 28px;
}

[data-rk] .ju367v27 {
  height: 30px;
}

[data-rk] .ju367v28 {
  height: 32px;
}

[data-rk] .ju367v29 {
  height: 34px;
}

[data-rk] .ju367v2a {
  height: 36px;
}

[data-rk] .ju367v2b {
  height: 40px;
}

[data-rk] .ju367v2c {
  height: 44px;
}

[data-rk] .ju367v2d {
  height: 48px;
}

[data-rk] .ju367v2e {
  height: 54px;
}

[data-rk] .ju367v2f {
  height: 60px;
}

[data-rk] .ju367v2g {
  height: 200px;
}

[data-rk] .ju367v2h {
  height: 100%;
}

[data-rk] .ju367v2i {
  height: -moz-max-content;
  height: max-content;
}

[data-rk] .ju367v2j {
  justify-content: flex-start;
}

[data-rk] .ju367v2k {
  justify-content: flex-end;
}

[data-rk] .ju367v2l {
  justify-content: center;
}

[data-rk] .ju367v2m {
  justify-content: space-between;
}

[data-rk] .ju367v2n {
  justify-content: space-around;
}

[data-rk] .ju367v2o {
  text-align: left;
}

[data-rk] .ju367v2p {
  text-align: center;
}

[data-rk] .ju367v2q {
  text-align: inherit;
}

[data-rk] .ju367v2r {
  margin-bottom: 0;
}

[data-rk] .ju367v2s {
  margin-bottom: 1px;
}

[data-rk] .ju367v2t {
  margin-bottom: 2px;
}

[data-rk] .ju367v2u {
  margin-bottom: 3px;
}

[data-rk] .ju367v2v {
  margin-bottom: 4px;
}

[data-rk] .ju367v2w {
  margin-bottom: 5px;
}

[data-rk] .ju367v2x {
  margin-bottom: 6px;
}

[data-rk] .ju367v2y {
  margin-bottom: 8px;
}

[data-rk] .ju367v2z {
  margin-bottom: 10px;
}

[data-rk] .ju367v30 {
  margin-bottom: 12px;
}

[data-rk] .ju367v31 {
  margin-bottom: 14px;
}

[data-rk] .ju367v32 {
  margin-bottom: 16px;
}

[data-rk] .ju367v33 {
  margin-bottom: 18px;
}

[data-rk] .ju367v34 {
  margin-bottom: 20px;
}

[data-rk] .ju367v35 {
  margin-bottom: 24px;
}

[data-rk] .ju367v36 {
  margin-bottom: 28px;
}

[data-rk] .ju367v37 {
  margin-bottom: 32px;
}

[data-rk] .ju367v38 {
  margin-bottom: 36px;
}

[data-rk] .ju367v39 {
  margin-bottom: 44px;
}

[data-rk] .ju367v3a {
  margin-bottom: 64px;
}

[data-rk] .ju367v3b {
  margin-bottom: -1px;
}

[data-rk] .ju367v3c {
  margin-left: 0;
}

[data-rk] .ju367v3d {
  margin-left: 1px;
}

[data-rk] .ju367v3e {
  margin-left: 2px;
}

[data-rk] .ju367v3f {
  margin-left: 3px;
}

[data-rk] .ju367v3g {
  margin-left: 4px;
}

[data-rk] .ju367v3h {
  margin-left: 5px;
}

[data-rk] .ju367v3i {
  margin-left: 6px;
}

[data-rk] .ju367v3j {
  margin-left: 8px;
}

[data-rk] .ju367v3k {
  margin-left: 10px;
}

[data-rk] .ju367v3l {
  margin-left: 12px;
}

[data-rk] .ju367v3m {
  margin-left: 14px;
}

[data-rk] .ju367v3n {
  margin-left: 16px;
}

[data-rk] .ju367v3o {
  margin-left: 18px;
}

[data-rk] .ju367v3p {
  margin-left: 20px;
}

[data-rk] .ju367v3q {
  margin-left: 24px;
}

[data-rk] .ju367v3r {
  margin-left: 28px;
}

[data-rk] .ju367v3s {
  margin-left: 32px;
}

[data-rk] .ju367v3t {
  margin-left: 36px;
}

[data-rk] .ju367v3u {
  margin-left: 44px;
}

[data-rk] .ju367v3v {
  margin-left: 64px;
}

[data-rk] .ju367v3w {
  margin-left: -1px;
}

[data-rk] .ju367v3x {
  margin-right: 0;
}

[data-rk] .ju367v3y {
  margin-right: 1px;
}

[data-rk] .ju367v3z {
  margin-right: 2px;
}

[data-rk] .ju367v40 {
  margin-right: 3px;
}

[data-rk] .ju367v41 {
  margin-right: 4px;
}

[data-rk] .ju367v42 {
  margin-right: 5px;
}

[data-rk] .ju367v43 {
  margin-right: 6px;
}

[data-rk] .ju367v44 {
  margin-right: 8px;
}

[data-rk] .ju367v45 {
  margin-right: 10px;
}

[data-rk] .ju367v46 {
  margin-right: 12px;
}

[data-rk] .ju367v47 {
  margin-right: 14px;
}

[data-rk] .ju367v48 {
  margin-right: 16px;
}

[data-rk] .ju367v49 {
  margin-right: 18px;
}

[data-rk] .ju367v4a {
  margin-right: 20px;
}

[data-rk] .ju367v4b {
  margin-right: 24px;
}

[data-rk] .ju367v4c {
  margin-right: 28px;
}

[data-rk] .ju367v4d {
  margin-right: 32px;
}

[data-rk] .ju367v4e {
  margin-right: 36px;
}

[data-rk] .ju367v4f {
  margin-right: 44px;
}

[data-rk] .ju367v4g {
  margin-right: 64px;
}

[data-rk] .ju367v4h {
  margin-right: -1px;
}

[data-rk] .ju367v4i {
  margin-top: 0;
}

[data-rk] .ju367v4j {
  margin-top: 1px;
}

[data-rk] .ju367v4k {
  margin-top: 2px;
}

[data-rk] .ju367v4l {
  margin-top: 3px;
}

[data-rk] .ju367v4m {
  margin-top: 4px;
}

[data-rk] .ju367v4n {
  margin-top: 5px;
}

[data-rk] .ju367v4o {
  margin-top: 6px;
}

[data-rk] .ju367v4p {
  margin-top: 8px;
}

[data-rk] .ju367v4q {
  margin-top: 10px;
}

[data-rk] .ju367v4r {
  margin-top: 12px;
}

[data-rk] .ju367v4s {
  margin-top: 14px;
}

[data-rk] .ju367v4t {
  margin-top: 16px;
}

[data-rk] .ju367v4u {
  margin-top: 18px;
}

[data-rk] .ju367v4v {
  margin-top: 20px;
}

[data-rk] .ju367v4w {
  margin-top: 24px;
}

[data-rk] .ju367v4x {
  margin-top: 28px;
}

[data-rk] .ju367v4y {
  margin-top: 32px;
}

[data-rk] .ju367v4z {
  margin-top: 36px;
}

[data-rk] .ju367v50 {
  margin-top: 44px;
}

[data-rk] .ju367v51 {
  margin-top: 64px;
}

[data-rk] .ju367v52 {
  margin-top: -1px;
}

[data-rk] .ju367v53 {
  max-width: 1px;
}

[data-rk] .ju367v54 {
  max-width: 2px;
}

[data-rk] .ju367v55 {
  max-width: 4px;
}

[data-rk] .ju367v56 {
  max-width: 8px;
}

[data-rk] .ju367v57 {
  max-width: 12px;
}

[data-rk] .ju367v58 {
  max-width: 20px;
}

[data-rk] .ju367v59 {
  max-width: 24px;
}

[data-rk] .ju367v5a {
  max-width: 28px;
}

[data-rk] .ju367v5b {
  max-width: 30px;
}

[data-rk] .ju367v5c {
  max-width: 32px;
}

[data-rk] .ju367v5d {
  max-width: 34px;
}

[data-rk] .ju367v5e {
  max-width: 36px;
}

[data-rk] .ju367v5f {
  max-width: 40px;
}

[data-rk] .ju367v5g {
  max-width: 44px;
}

[data-rk] .ju367v5h {
  max-width: 48px;
}

[data-rk] .ju367v5i {
  max-width: 54px;
}

[data-rk] .ju367v5j {
  max-width: 60px;
}

[data-rk] .ju367v5k {
  max-width: 200px;
}

[data-rk] .ju367v5l {
  max-width: 100%;
}

[data-rk] .ju367v5m {
  max-width: -moz-max-content;
  max-width: max-content;
}

[data-rk] .ju367v5n {
  min-width: 1px;
}

[data-rk] .ju367v5o {
  min-width: 2px;
}

[data-rk] .ju367v5p {
  min-width: 4px;
}

[data-rk] .ju367v5q {
  min-width: 8px;
}

[data-rk] .ju367v5r {
  min-width: 12px;
}

[data-rk] .ju367v5s {
  min-width: 20px;
}

[data-rk] .ju367v5t {
  min-width: 24px;
}

[data-rk] .ju367v5u {
  min-width: 28px;
}

[data-rk] .ju367v5v {
  min-width: 30px;
}

[data-rk] .ju367v5w {
  min-width: 32px;
}

[data-rk] .ju367v5x {
  min-width: 34px;
}

[data-rk] .ju367v5y {
  min-width: 36px;
}

[data-rk] .ju367v5z {
  min-width: 40px;
}

[data-rk] .ju367v60 {
  min-width: 44px;
}

[data-rk] .ju367v61 {
  min-width: 48px;
}

[data-rk] .ju367v62 {
  min-width: 54px;
}

[data-rk] .ju367v63 {
  min-width: 60px;
}

[data-rk] .ju367v64 {
  min-width: 200px;
}

[data-rk] .ju367v65 {
  min-width: 100%;
}

[data-rk] .ju367v66 {
  min-width: -moz-max-content;
  min-width: max-content;
}

[data-rk] .ju367v67 {
  overflow: hidden;
}

[data-rk] .ju367v68 {
  padding-bottom: 0;
}

[data-rk] .ju367v69 {
  padding-bottom: 1px;
}

[data-rk] .ju367v6a {
  padding-bottom: 2px;
}

[data-rk] .ju367v6b {
  padding-bottom: 3px;
}

[data-rk] .ju367v6c {
  padding-bottom: 4px;
}

[data-rk] .ju367v6d {
  padding-bottom: 5px;
}

[data-rk] .ju367v6e {
  padding-bottom: 6px;
}

[data-rk] .ju367v6f {
  padding-bottom: 8px;
}

[data-rk] .ju367v6g {
  padding-bottom: 10px;
}

[data-rk] .ju367v6h {
  padding-bottom: 12px;
}

[data-rk] .ju367v6i {
  padding-bottom: 14px;
}

[data-rk] .ju367v6j {
  padding-bottom: 16px;
}

[data-rk] .ju367v6k {
  padding-bottom: 18px;
}

[data-rk] .ju367v6l {
  padding-bottom: 20px;
}

[data-rk] .ju367v6m {
  padding-bottom: 24px;
}

[data-rk] .ju367v6n {
  padding-bottom: 28px;
}

[data-rk] .ju367v6o {
  padding-bottom: 32px;
}

[data-rk] .ju367v6p {
  padding-bottom: 36px;
}

[data-rk] .ju367v6q {
  padding-bottom: 44px;
}

[data-rk] .ju367v6r {
  padding-bottom: 64px;
}

[data-rk] .ju367v6s {
  padding-bottom: -1px;
}

[data-rk] .ju367v6t {
  padding-left: 0;
}

[data-rk] .ju367v6u {
  padding-left: 1px;
}

[data-rk] .ju367v6v {
  padding-left: 2px;
}

[data-rk] .ju367v6w {
  padding-left: 3px;
}

[data-rk] .ju367v6x {
  padding-left: 4px;
}

[data-rk] .ju367v6y {
  padding-left: 5px;
}

[data-rk] .ju367v6z {
  padding-left: 6px;
}

[data-rk] .ju367v70 {
  padding-left: 8px;
}

[data-rk] .ju367v71 {
  padding-left: 10px;
}

[data-rk] .ju367v72 {
  padding-left: 12px;
}

[data-rk] .ju367v73 {
  padding-left: 14px;
}

[data-rk] .ju367v74 {
  padding-left: 16px;
}

[data-rk] .ju367v75 {
  padding-left: 18px;
}

[data-rk] .ju367v76 {
  padding-left: 20px;
}

[data-rk] .ju367v77 {
  padding-left: 24px;
}

[data-rk] .ju367v78 {
  padding-left: 28px;
}

[data-rk] .ju367v79 {
  padding-left: 32px;
}

[data-rk] .ju367v7a {
  padding-left: 36px;
}

[data-rk] .ju367v7b {
  padding-left: 44px;
}

[data-rk] .ju367v7c {
  padding-left: 64px;
}

[data-rk] .ju367v7d {
  padding-left: -1px;
}

[data-rk] .ju367v7e {
  padding-right: 0;
}

[data-rk] .ju367v7f {
  padding-right: 1px;
}

[data-rk] .ju367v7g {
  padding-right: 2px;
}

[data-rk] .ju367v7h {
  padding-right: 3px;
}

[data-rk] .ju367v7i {
  padding-right: 4px;
}

[data-rk] .ju367v7j {
  padding-right: 5px;
}

[data-rk] .ju367v7k {
  padding-right: 6px;
}

[data-rk] .ju367v7l {
  padding-right: 8px;
}

[data-rk] .ju367v7m {
  padding-right: 10px;
}

[data-rk] .ju367v7n {
  padding-right: 12px;
}

[data-rk] .ju367v7o {
  padding-right: 14px;
}

[data-rk] .ju367v7p {
  padding-right: 16px;
}

[data-rk] .ju367v7q {
  padding-right: 18px;
}

[data-rk] .ju367v7r {
  padding-right: 20px;
}

[data-rk] .ju367v7s {
  padding-right: 24px;
}

[data-rk] .ju367v7t {
  padding-right: 28px;
}

[data-rk] .ju367v7u {
  padding-right: 32px;
}

[data-rk] .ju367v7v {
  padding-right: 36px;
}

[data-rk] .ju367v7w {
  padding-right: 44px;
}

[data-rk] .ju367v7x {
  padding-right: 64px;
}

[data-rk] .ju367v7y {
  padding-right: -1px;
}

[data-rk] .ju367v7z {
  padding-top: 0;
}

[data-rk] .ju367v80 {
  padding-top: 1px;
}

[data-rk] .ju367v81 {
  padding-top: 2px;
}

[data-rk] .ju367v82 {
  padding-top: 3px;
}

[data-rk] .ju367v83 {
  padding-top: 4px;
}

[data-rk] .ju367v84 {
  padding-top: 5px;
}

[data-rk] .ju367v85 {
  padding-top: 6px;
}

[data-rk] .ju367v86 {
  padding-top: 8px;
}

[data-rk] .ju367v87 {
  padding-top: 10px;
}

[data-rk] .ju367v88 {
  padding-top: 12px;
}

[data-rk] .ju367v89 {
  padding-top: 14px;
}

[data-rk] .ju367v8a {
  padding-top: 16px;
}

[data-rk] .ju367v8b {
  padding-top: 18px;
}

[data-rk] .ju367v8c {
  padding-top: 20px;
}

[data-rk] .ju367v8d {
  padding-top: 24px;
}

[data-rk] .ju367v8e {
  padding-top: 28px;
}

[data-rk] .ju367v8f {
  padding-top: 32px;
}

[data-rk] .ju367v8g {
  padding-top: 36px;
}

[data-rk] .ju367v8h {
  padding-top: 44px;
}

[data-rk] .ju367v8i {
  padding-top: 64px;
}

[data-rk] .ju367v8j {
  padding-top: -1px;
}

[data-rk] .ju367v8k {
  position: absolute;
}

[data-rk] .ju367v8l {
  position: fixed;
}

[data-rk] .ju367v8m {
  position: relative;
}

[data-rk] .ju367v8n {
  right: 0;
}

[data-rk] .ju367v8o {
  transition: all .125s;
}

[data-rk] .ju367v8p {
  transition: transform .125s;
}

[data-rk] .ju367v8q {
  user-select: none;
}

[data-rk] .ju367v8r {
  width: 1px;
}

[data-rk] .ju367v8s {
  width: 2px;
}

[data-rk] .ju367v8t {
  width: 4px;
}

[data-rk] .ju367v8u {
  width: 8px;
}

[data-rk] .ju367v8v {
  width: 12px;
}

[data-rk] .ju367v8w {
  width: 20px;
}

[data-rk] .ju367v8x {
  width: 24px;
}

[data-rk] .ju367v8y {
  width: 28px;
}

[data-rk] .ju367v8z {
  width: 30px;
}

[data-rk] .ju367v90 {
  width: 32px;
}

[data-rk] .ju367v91 {
  width: 34px;
}

[data-rk] .ju367v92 {
  width: 36px;
}

[data-rk] .ju367v93 {
  width: 40px;
}

[data-rk] .ju367v94 {
  width: 44px;
}

[data-rk] .ju367v95 {
  width: 48px;
}

[data-rk] .ju367v96 {
  width: 54px;
}

[data-rk] .ju367v97 {
  width: 60px;
}

[data-rk] .ju367v98 {
  width: 200px;
}

[data-rk] .ju367v99 {
  width: 100%;
}

[data-rk] .ju367v9a {
  width: -moz-max-content;
  width: max-content;
}

[data-rk] .ju367v9b {
  -webkit-backdrop-filter: var(--rk-blurs-modalOverlay);
  backdrop-filter: var(--rk-blurs-modalOverlay);
}

[data-rk] .ju367v9c, [data-rk] .ju367v9d:hover, [data-rk] .ju367v9e:active {
  background: var(--rk-colors-accentColor);
}

[data-rk] .ju367v9f, [data-rk] .ju367v9g:hover, [data-rk] .ju367v9h:active {
  background: var(--rk-colors-accentColorForeground);
}

[data-rk] .ju367v9i, [data-rk] .ju367v9j:hover, [data-rk] .ju367v9k:active {
  background: var(--rk-colors-actionButtonBorder);
}

[data-rk] .ju367v9l, [data-rk] .ju367v9m:hover, [data-rk] .ju367v9n:active {
  background: var(--rk-colors-actionButtonBorderMobile);
}

[data-rk] .ju367v9o, [data-rk] .ju367v9p:hover, [data-rk] .ju367v9q:active {
  background: var(--rk-colors-actionButtonSecondaryBackground);
}

[data-rk] .ju367v9r, [data-rk] .ju367v9s:hover, [data-rk] .ju367v9t:active {
  background: var(--rk-colors-closeButton);
}

[data-rk] .ju367v9u, [data-rk] .ju367v9v:hover, [data-rk] .ju367v9w:active {
  background: var(--rk-colors-closeButtonBackground);
}

[data-rk] .ju367v9x, [data-rk] .ju367v9y:hover, [data-rk] .ju367v9z:active {
  background: var(--rk-colors-connectButtonBackground);
}

[data-rk] .ju367va0, [data-rk] .ju367va1:hover, [data-rk] .ju367va2:active {
  background: var(--rk-colors-connectButtonBackgroundError);
}

[data-rk] .ju367va3, [data-rk] .ju367va4:hover, [data-rk] .ju367va5:active {
  background: var(--rk-colors-connectButtonInnerBackground);
}

[data-rk] .ju367va6, [data-rk] .ju367va7:hover, [data-rk] .ju367va8:active {
  background: var(--rk-colors-connectButtonText);
}

[data-rk] .ju367va9, [data-rk] .ju367vaa:hover, [data-rk] .ju367vab:active {
  background: var(--rk-colors-connectButtonTextError);
}

[data-rk] .ju367vac, [data-rk] .ju367vad:hover, [data-rk] .ju367vae:active {
  background: var(--rk-colors-connectionIndicator);
}

[data-rk] .ju367vaf, [data-rk] .ju367vag:hover, [data-rk] .ju367vah:active {
  background: var(--rk-colors-downloadBottomCardBackground);
}

[data-rk] .ju367vai, [data-rk] .ju367vaj:hover, [data-rk] .ju367vak:active {
  background: var(--rk-colors-downloadTopCardBackground);
}

[data-rk] .ju367val, [data-rk] .ju367vam:hover, [data-rk] .ju367van:active {
  background: var(--rk-colors-error);
}

[data-rk] .ju367vao, [data-rk] .ju367vap:hover, [data-rk] .ju367vaq:active {
  background: var(--rk-colors-generalBorder);
}

[data-rk] .ju367var, [data-rk] .ju367vas:hover, [data-rk] .ju367vat:active {
  background: var(--rk-colors-generalBorderDim);
}

[data-rk] .ju367vau, [data-rk] .ju367vav:hover, [data-rk] .ju367vaw:active {
  background: var(--rk-colors-menuItemBackground);
}

[data-rk] .ju367vax, [data-rk] .ju367vay:hover, [data-rk] .ju367vaz:active {
  background: var(--rk-colors-modalBackdrop);
}

[data-rk] .ju367vb0, [data-rk] .ju367vb1:hover, [data-rk] .ju367vb2:active {
  background: var(--rk-colors-modalBackground);
}

[data-rk] .ju367vb3, [data-rk] .ju367vb4:hover, [data-rk] .ju367vb5:active {
  background: var(--rk-colors-modalBorder);
}

[data-rk] .ju367vb6, [data-rk] .ju367vb7:hover, [data-rk] .ju367vb8:active {
  background: var(--rk-colors-modalText);
}

[data-rk] .ju367vb9, [data-rk] .ju367vba:hover, [data-rk] .ju367vbb:active {
  background: var(--rk-colors-modalTextDim);
}

[data-rk] .ju367vbc, [data-rk] .ju367vbd:hover, [data-rk] .ju367vbe:active {
  background: var(--rk-colors-modalTextSecondary);
}

[data-rk] .ju367vbf, [data-rk] .ju367vbg:hover, [data-rk] .ju367vbh:active {
  background: var(--rk-colors-profileAction);
}

[data-rk] .ju367vbi, [data-rk] .ju367vbj:hover, [data-rk] .ju367vbk:active {
  background: var(--rk-colors-profileActionHover);
}

[data-rk] .ju367vbl, [data-rk] .ju367vbm:hover, [data-rk] .ju367vbn:active {
  background: var(--rk-colors-profileForeground);
}

[data-rk] .ju367vbo, [data-rk] .ju367vbp:hover, [data-rk] .ju367vbq:active {
  background: var(--rk-colors-selectedOptionBorder);
}

[data-rk] .ju367vbr, [data-rk] .ju367vbs:hover, [data-rk] .ju367vbt:active {
  background: var(--rk-colors-standby);
}

[data-rk] .ju367vbu, [data-rk] .ju367vbv:hover, [data-rk] .ju367vbw:active {
  border-color: var(--rk-colors-accentColor);
}

[data-rk] .ju367vbx, [data-rk] .ju367vby:hover, [data-rk] .ju367vbz:active {
  border-color: var(--rk-colors-accentColorForeground);
}

[data-rk] .ju367vc0, [data-rk] .ju367vc1:hover, [data-rk] .ju367vc2:active {
  border-color: var(--rk-colors-actionButtonBorder);
}

[data-rk] .ju367vc3, [data-rk] .ju367vc4:hover, [data-rk] .ju367vc5:active {
  border-color: var(--rk-colors-actionButtonBorderMobile);
}

[data-rk] .ju367vc6, [data-rk] .ju367vc7:hover, [data-rk] .ju367vc8:active {
  border-color: var(--rk-colors-actionButtonSecondaryBackground);
}

[data-rk] .ju367vc9, [data-rk] .ju367vca:hover, [data-rk] .ju367vcb:active {
  border-color: var(--rk-colors-closeButton);
}

[data-rk] .ju367vcc, [data-rk] .ju367vcd:hover, [data-rk] .ju367vce:active {
  border-color: var(--rk-colors-closeButtonBackground);
}

[data-rk] .ju367vcf, [data-rk] .ju367vcg:hover, [data-rk] .ju367vch:active {
  border-color: var(--rk-colors-connectButtonBackground);
}

[data-rk] .ju367vci, [data-rk] .ju367vcj:hover, [data-rk] .ju367vck:active {
  border-color: var(--rk-colors-connectButtonBackgroundError);
}

[data-rk] .ju367vcl, [data-rk] .ju367vcm:hover, [data-rk] .ju367vcn:active {
  border-color: var(--rk-colors-connectButtonInnerBackground);
}

[data-rk] .ju367vco, [data-rk] .ju367vcp:hover, [data-rk] .ju367vcq:active {
  border-color: var(--rk-colors-connectButtonText);
}

[data-rk] .ju367vcr, [data-rk] .ju367vcs:hover, [data-rk] .ju367vct:active {
  border-color: var(--rk-colors-connectButtonTextError);
}

[data-rk] .ju367vcu, [data-rk] .ju367vcv:hover, [data-rk] .ju367vcw:active {
  border-color: var(--rk-colors-connectionIndicator);
}

[data-rk] .ju367vcx, [data-rk] .ju367vcy:hover, [data-rk] .ju367vcz:active {
  border-color: var(--rk-colors-downloadBottomCardBackground);
}

[data-rk] .ju367vd0, [data-rk] .ju367vd1:hover, [data-rk] .ju367vd2:active {
  border-color: var(--rk-colors-downloadTopCardBackground);
}

[data-rk] .ju367vd3, [data-rk] .ju367vd4:hover, [data-rk] .ju367vd5:active {
  border-color: var(--rk-colors-error);
}

[data-rk] .ju367vd6, [data-rk] .ju367vd7:hover, [data-rk] .ju367vd8:active {
  border-color: var(--rk-colors-generalBorder);
}

[data-rk] .ju367vd9, [data-rk] .ju367vda:hover, [data-rk] .ju367vdb:active {
  border-color: var(--rk-colors-generalBorderDim);
}

[data-rk] .ju367vdc, [data-rk] .ju367vdd:hover, [data-rk] .ju367vde:active {
  border-color: var(--rk-colors-menuItemBackground);
}

[data-rk] .ju367vdf, [data-rk] .ju367vdg:hover, [data-rk] .ju367vdh:active {
  border-color: var(--rk-colors-modalBackdrop);
}

[data-rk] .ju367vdi, [data-rk] .ju367vdj:hover, [data-rk] .ju367vdk:active {
  border-color: var(--rk-colors-modalBackground);
}

[data-rk] .ju367vdl, [data-rk] .ju367vdm:hover, [data-rk] .ju367vdn:active {
  border-color: var(--rk-colors-modalBorder);
}

[data-rk] .ju367vdo, [data-rk] .ju367vdp:hover, [data-rk] .ju367vdq:active {
  border-color: var(--rk-colors-modalText);
}

[data-rk] .ju367vdr, [data-rk] .ju367vds:hover, [data-rk] .ju367vdt:active {
  border-color: var(--rk-colors-modalTextDim);
}

[data-rk] .ju367vdu, [data-rk] .ju367vdv:hover, [data-rk] .ju367vdw:active {
  border-color: var(--rk-colors-modalTextSecondary);
}

[data-rk] .ju367vdx, [data-rk] .ju367vdy:hover, [data-rk] .ju367vdz:active {
  border-color: var(--rk-colors-profileAction);
}

[data-rk] .ju367ve0, [data-rk] .ju367ve1:hover, [data-rk] .ju367ve2:active {
  border-color: var(--rk-colors-profileActionHover);
}

[data-rk] .ju367ve3, [data-rk] .ju367ve4:hover, [data-rk] .ju367ve5:active {
  border-color: var(--rk-colors-profileForeground);
}

[data-rk] .ju367ve6, [data-rk] .ju367ve7:hover, [data-rk] .ju367ve8:active {
  border-color: var(--rk-colors-selectedOptionBorder);
}

[data-rk] .ju367ve9, [data-rk] .ju367vea:hover, [data-rk] .ju367veb:active {
  border-color: var(--rk-colors-standby);
}

[data-rk] .ju367vec, [data-rk] .ju367ved:hover, [data-rk] .ju367vee:active {
  box-shadow: var(--rk-shadows-connectButton);
}

[data-rk] .ju367vef, [data-rk] .ju367veg:hover, [data-rk] .ju367veh:active {
  box-shadow: var(--rk-shadows-dialog);
}

[data-rk] .ju367vei, [data-rk] .ju367vej:hover, [data-rk] .ju367vek:active {
  box-shadow: var(--rk-shadows-profileDetailsAction);
}

[data-rk] .ju367vel, [data-rk] .ju367vem:hover, [data-rk] .ju367ven:active {
  box-shadow: var(--rk-shadows-selectedOption);
}

[data-rk] .ju367veo, [data-rk] .ju367vep:hover, [data-rk] .ju367veq:active {
  box-shadow: var(--rk-shadows-selectedWallet);
}

[data-rk] .ju367ver, [data-rk] .ju367ves:hover, [data-rk] .ju367vet:active {
  box-shadow: var(--rk-shadows-walletLogo);
}

[data-rk] .ju367veu, [data-rk] .ju367vev:hover, [data-rk] .ju367vew:active {
  color: var(--rk-colors-accentColor);
}

[data-rk] .ju367vex, [data-rk] .ju367vey:hover, [data-rk] .ju367vez:active {
  color: var(--rk-colors-accentColorForeground);
}

[data-rk] .ju367vf0, [data-rk] .ju367vf1:hover, [data-rk] .ju367vf2:active {
  color: var(--rk-colors-actionButtonBorder);
}

[data-rk] .ju367vf3, [data-rk] .ju367vf4:hover, [data-rk] .ju367vf5:active {
  color: var(--rk-colors-actionButtonBorderMobile);
}

[data-rk] .ju367vf6, [data-rk] .ju367vf7:hover, [data-rk] .ju367vf8:active {
  color: var(--rk-colors-actionButtonSecondaryBackground);
}

[data-rk] .ju367vf9, [data-rk] .ju367vfa:hover, [data-rk] .ju367vfb:active {
  color: var(--rk-colors-closeButton);
}

[data-rk] .ju367vfc, [data-rk] .ju367vfd:hover, [data-rk] .ju367vfe:active {
  color: var(--rk-colors-closeButtonBackground);
}

[data-rk] .ju367vff, [data-rk] .ju367vfg:hover, [data-rk] .ju367vfh:active {
  color: var(--rk-colors-connectButtonBackground);
}

[data-rk] .ju367vfi, [data-rk] .ju367vfj:hover, [data-rk] .ju367vfk:active {
  color: var(--rk-colors-connectButtonBackgroundError);
}

[data-rk] .ju367vfl, [data-rk] .ju367vfm:hover, [data-rk] .ju367vfn:active {
  color: var(--rk-colors-connectButtonInnerBackground);
}

[data-rk] .ju367vfo, [data-rk] .ju367vfp:hover, [data-rk] .ju367vfq:active {
  color: var(--rk-colors-connectButtonText);
}

[data-rk] .ju367vfr, [data-rk] .ju367vfs:hover, [data-rk] .ju367vft:active {
  color: var(--rk-colors-connectButtonTextError);
}

[data-rk] .ju367vfu, [data-rk] .ju367vfv:hover, [data-rk] .ju367vfw:active {
  color: var(--rk-colors-connectionIndicator);
}

[data-rk] .ju367vfx, [data-rk] .ju367vfy:hover, [data-rk] .ju367vfz:active {
  color: var(--rk-colors-downloadBottomCardBackground);
}

[data-rk] .ju367vg0, [data-rk] .ju367vg1:hover, [data-rk] .ju367vg2:active {
  color: var(--rk-colors-downloadTopCardBackground);
}

[data-rk] .ju367vg3, [data-rk] .ju367vg4:hover, [data-rk] .ju367vg5:active {
  color: var(--rk-colors-error);
}

[data-rk] .ju367vg6, [data-rk] .ju367vg7:hover, [data-rk] .ju367vg8:active {
  color: var(--rk-colors-generalBorder);
}

[data-rk] .ju367vg9, [data-rk] .ju367vga:hover, [data-rk] .ju367vgb:active {
  color: var(--rk-colors-generalBorderDim);
}

[data-rk] .ju367vgc, [data-rk] .ju367vgd:hover, [data-rk] .ju367vge:active {
  color: var(--rk-colors-menuItemBackground);
}

[data-rk] .ju367vgf, [data-rk] .ju367vgg:hover, [data-rk] .ju367vgh:active {
  color: var(--rk-colors-modalBackdrop);
}

[data-rk] .ju367vgi, [data-rk] .ju367vgj:hover, [data-rk] .ju367vgk:active {
  color: var(--rk-colors-modalBackground);
}

[data-rk] .ju367vgl, [data-rk] .ju367vgm:hover, [data-rk] .ju367vgn:active {
  color: var(--rk-colors-modalBorder);
}

[data-rk] .ju367vgo, [data-rk] .ju367vgp:hover, [data-rk] .ju367vgq:active {
  color: var(--rk-colors-modalText);
}

[data-rk] .ju367vgr, [data-rk] .ju367vgs:hover, [data-rk] .ju367vgt:active {
  color: var(--rk-colors-modalTextDim);
}

[data-rk] .ju367vgu, [data-rk] .ju367vgv:hover, [data-rk] .ju367vgw:active {
  color: var(--rk-colors-modalTextSecondary);
}

[data-rk] .ju367vgx, [data-rk] .ju367vgy:hover, [data-rk] .ju367vgz:active {
  color: var(--rk-colors-profileAction);
}

[data-rk] .ju367vh0, [data-rk] .ju367vh1:hover, [data-rk] .ju367vh2:active {
  color: var(--rk-colors-profileActionHover);
}

[data-rk] .ju367vh3, [data-rk] .ju367vh4:hover, [data-rk] .ju367vh5:active {
  color: var(--rk-colors-profileForeground);
}

[data-rk] .ju367vh6, [data-rk] .ju367vh7:hover, [data-rk] .ju367vh8:active {
  color: var(--rk-colors-selectedOptionBorder);
}

[data-rk] .ju367vh9, [data-rk] .ju367vha:hover, [data-rk] .ju367vhb:active {
  color: var(--rk-colors-standby);
}

@media screen and (min-width: 768px) {
  [data-rk] .ju367v1 {
    align-items: flex-start;
  }

  [data-rk] .ju367v3 {
    align-items: flex-end;
  }

  [data-rk] .ju367v5 {
    align-items: center;
  }

  [data-rk] .ju367v7 {
    display: none;
  }

  [data-rk] .ju367v9 {
    display: block;
  }

  [data-rk] .ju367vb {
    display: flex;
  }

  [data-rk] .ju367vd {
    display: inline;
  }
}

[data-rk] ._12cbo8i3, [data-rk] ._12cbo8i3:after {
  --_12cbo8i0: 1;
  --_12cbo8i1: 1;
}

[data-rk] ._12cbo8i3:hover {
  transform: scale(var(--_12cbo8i0) );
}

[data-rk] ._12cbo8i3:active {
  transform: scale(var(--_12cbo8i1) );
}

[data-rk] ._12cbo8i3:active:after {
  content: "";
  transform: scale(calc((1 / var(--_12cbo8i1) ) * var(--_12cbo8i0) ) );
  display: block;
  position: absolute;
  inset: -1px;
}

[data-rk] ._12cbo8i4, [data-rk] ._12cbo8i4:after {
  --_12cbo8i0: 1.025;
}

[data-rk] ._12cbo8i5, [data-rk] ._12cbo8i5:after {
  --_12cbo8i0: 1.1;
}

[data-rk] ._12cbo8i6, [data-rk] ._12cbo8i6:after {
  --_12cbo8i1: .95;
}

[data-rk] ._12cbo8i7, [data-rk] ._12cbo8i7:after {
  --_12cbo8i1: .9;
}

@keyframes _1luule41 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

[data-rk] ._1luule42 {
  animation: _1luule41 3s linear infinite;
}

[data-rk] ._1luule43 {
  height: 21px;
  width: 21px;
  background: conic-gradient(from 180deg, #4892fe00 0deg, currentColor 282.04deg, #4892fe00 319.86deg 360deg);
}

@keyframes _9pm4ki0 {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes _9pm4ki1 {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

[data-rk] ._9pm4ki3 {
  z-index: 2147483646;
  padding: 200px;
  animation: _9pm4ki1 .15s;
  inset: -200px;
  transform: translateZ(0);
}

[data-rk] ._9pm4ki5 {
  max-width: 100vw;
  animation: _9pm4ki0 .35s cubic-bezier(.15, 1.15, .6, 1), _9pm4ki1 .15s;
}

[data-rk] ._1ckjpok1 {
  box-sizing: content-box;
  max-width: 100vw;
  width: 360px;
}

[data-rk] ._1ckjpok2 {
  width: 100vw;
}

[data-rk] ._1ckjpok3 {
  width: 768px;
}

[data-rk] ._1ckjpok4 {
  min-width: 368px;
  width: 368px;
}

[data-rk] ._1ckjpok6 {
  box-sizing: border-box;
  width: 100vw;
  border-width: 0;
}

@media screen and (min-width: 768px) {
  [data-rk] ._1ckjpok1 {
    width: 360px;
  }

  [data-rk] ._1ckjpok2 {
    width: 480px;
  }

  [data-rk] ._1ckjpok3 {
    width: 720px;
  }

  [data-rk] ._1ckjpok4 {
    min-width: 368px;
    width: 368px;
  }
}

@media screen and (max-width: 767px) {
  [data-rk] ._1ckjpok7 {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin-top: -200px;
    padding-bottom: 200px;
    top: 200px;
  }
}

[data-rk] .v9horb0:hover {
  background: unset;
}

[data-rk] ._18dqw9x0 {
  max-height: 454px;
  overflow-y: auto;
}

[data-rk] ._18dqw9x1 {
  max-height: 454px;
  scrollbar-width: none;
  overflow-x: hidden;
  overflow-y: auto;
}

[data-rk] ._18dqw9x1::-webkit-scrollbar {
  display: none;
}

[data-rk] .g5kl0l0 {
  border-color: #0000;
}

[data-rk] ._1vwt0cg0 {
  background: #fff;
}

[data-rk] ._1vwt0cg2 {
  max-height: 454px;
  overflow-y: auto;
}

[data-rk] ._1vwt0cg3 {
  min-width: 246px;
}

[data-rk] ._1vwt0cg4 {
  min-width: 100%;
}

@media screen and (min-width: 768px) {
  [data-rk] ._1vwt0cg3 {
    min-width: 287px;
  }
}

[data-rk] ._1am14410 {
  scrollbar-width: none;
  overflow: auto;
  transform: translateZ(0);
}

[data-rk] ._1am14410::-webkit-scrollbar {
  display: none;
}





/*# sourceMappingURL=index.a275289b.css.map */
